import ProgendaUtils from "shared/progenda_utils.js";
import InfiniteScroll from "react-infinite-scroller";
import ProgendaContext from "shared/progenda_context";
import { Link } from "react-router-dom";
import XSSFilter from "shared/components/xss_filter.tsx";
import HelenaProButton from "../../../admin/components/helena_pro/shared/HelenaProButton";
import DragAndDropModal from "../../../admin/components/calendars/show/drag_and_drop_modal";
import React from "react";

export default class CenterSuggester extends React.Component {

    constructor(props) {
        super(props);
        this.state = { maxResults: 10 };
        this.changeSearchView = props.changeSearchView
    }

    get parking() {
        return this.props.getParking();
    }

    componentDidUpdate() {
        const {loadingSuggestions, suggestions, groupedSuggestions, sharedMoment, selectedServiceCodes, getParking} = this.props;
        if(!loadingSuggestions && getParking) {
            $('.droppable_zone').droppable({
                drop: (event, ui) => {
                    const zone = $(event.target);
                    const suggestionIndex = zone.data('suggestion-index');
                    const dayIndex = zone.data('day-index');
                    const timeIndex = zone.data('time-index');

                    //the grouped suggestion are concatenate arrays
                    //the first value of each level is the day timestamps, the hour timestamps
                    //that's why we need to go to the second array [1] to retrieve the data
                    const correspondingSuggestion = groupedSuggestions[dayIndex][1][timeIndex][1][suggestionIndex];

                    var draggedElement = ui.helper[0];
                    const eventData = $(draggedElement).data('event');
                    const eventStartMoment = $(draggedElement).data('formerStart');
                    const eventEndMoment = $(draggedElement).data('formerEnd');

                    this.performConfirmDragging(
                        eventData.appointment.id,
                        eventData.calendar,
                        correspondingSuggestion,
                        eventStartMoment,
                        eventEndMoment,
                        () => {
                            //reset shardMoment
                            sharedMoment.setRange(suggestions[0].start, suggestions[0].stop);
                        },
                        () => {
                            //remove event from the parking lot and go back to the calendar view
                            this.parking.removeEvent(eventData.appointment.id);
                            this.changeSearchView(false);
                        }
                    );
                }
            });
            this.parking.disableEvents(selectedServiceCodes.length > 0 ? selectedServiceCodes[0] : null)
        }
    }

    performConfirmDragging = (eventId, eventCalendar, suggestion, previousStart, previousEnd, revertFunc, callbackFunc) => {
        const {sharedMoment} = this.props
        const calendar = suggestion.calendar
        /**
         * IMPORTANT:
         * below specifying the format is mandatory to exclude the timezone (`Z`) from date part
         * the newly created moment will have the timezone equal to this.props.calendar.timezone
         * these created big problems if the developer's computer was in a different timezone than
         * the one specified in props.
         */
        const eventStart = suggestion.start;
        const eventEnd = suggestion.stop;
        sharedMoment.setRange(eventStart, eventEnd);

        // create a light version of the event so we can control the start & end dates better
        const lightEvent = {
            id: eventId,
            start: eventStart,
            end: eventEnd,
            allDay: false,
            calendarSlug: eventCalendar.slug,
            serviceCode: this.props.selectedServiceCodes[0]
        };

        this._dragAndDropModal.setParams(
            lightEvent,
            previousStart,
            previousEnd,
            calendar,
            revertFunc,
            callbackFunc
        );
        this._dragAndDropModal.show();
    };

    setSelectedServiceCode = event => {
        var serviceCode = event.target.value;
        this.props.setSelectedServiceCodes([serviceCode]);
    };

    showMoreResults = () => {
        this.setState({ maxResults: this.state.maxResults + 10 });
    };
    render() {
        const {
            selectedServiceCodes,
            loadingSuggestions,
            suggestions,
            center,
            groupedSuggestions,
            goToSuggestion,
            useHelenaProDesign
        } = this.props;

        if (selectedServiceCodes.length === 0) {
            return (
                <div className="text-center">
                    <br />
                    <br />
                    <div className="alert alert-warning">
                        {I18n.get().t("no_results_search_explanation")}
                    </div>
                </div>
            );
        }
        if (loadingSuggestions) {
            return <div className="progenda-spinner"></div>;
        }
        if (suggestions.length === 0) {
            return (
                <div className="text-center">
                    <br />
                    <br />
                    <span className="alert alert-warning display-inline-block">
            {ProgendaContext.getContext() === "ADMIN"
                ? I18n.get().t("no_more_availabilities_on_admin_search")
                : (
                <XSSFilter
                    useSpan={true}
                    content={center.noAvailabilitiesText}
                    allowLinks={true}
                />
            ) || I18n.get().t("no_more_availabilities_on_patient_search")}
          </span>
                </div>
            );
        }

        return (
            <div>
                <DragAndDropModal ref={c => (this._dragAndDropModal = c)} />
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.showMoreResults}
                    hasMore={this.state.maxResults <= groupedSuggestions.length}
                    loader={
                        <div className="loader" key={0}>
                            Loading ...
                        </div>
                    }
                >
                    <table className="center-search-table table table-resp table-striped">
                        <tbody>
                        {groupedSuggestions
                            .slice(0, this.state.maxResults)
                            .map(([day, suggestions], dayIndex) => [
                                <tr
                                    key={`center-search-header-first-line-${day}`}
                                    className="center-search-header"
                                >
                                    <td colSpan="3">
                                        <h3>
                                            {ProgendaUtils.dateFormat(
                                                day,
                                                "long_date_format"
                                            ).capitalize()}
                                        </h3>
                                    </td>
                                </tr>,
                                <tr
                                    key={`center-search-header-second-line-${day}`}
                                    className="center-search-header"
                                >
                                    <th>{I18n.get().t("start")}</th>
                                    <th>{I18n.get().t(center.calendarDesignation)}</th>
                                    <th></th>
                                </tr>,
                                ...suggestions.map(([time, suggestions], timeIndex) =>
                                        suggestions.map((suggestion, index) => (
                                            <tr
                                                key={`first-group-availability-${index.toString()}`}
                                                className={"first-group-availability droppable_zone"}
                                                data-suggestion-index={index}
                                                data-day-index={dayIndex}
                                                data-time-index={timeIndex}
                                            >
                                                <td>
                                                    {ProgendaUtils.dateFormat(
                                                        suggestion.start,
                                                        "simple_time_format"
                                                    )}
                                                </td>
                                                <td>
                                                    {useHelenaProDesign ?
                                                        <div className="avatar-initials">
                                                            {suggestion.calendar.firstName.charAt(0)}{suggestion.calendar.lastName.charAt(0)}
                                                        </div>
                                                        :
                                                        <img
                                                            src={suggestion.calendar.smallPicture}
                                                            className="profile-thumb img-rounded"
                                                        />
                                                    }
                                                    &nbsp;&nbsp;
                                                    {suggestion.calendar.officialNameWithAdditional}
                                                </td>
                                                {useHelenaProDesign ?
                                                    <td className="center-search-buttons">
                                                        {ProgendaContext.getContext() === "ADMIN" ? (
                                                            <span>
                              <Link
                                  to={suggestion.url}
                                  className="btn btn-default"
                              >
                                  <span><span className="fas fa-plus"></span> &nbsp;</span>
                                  {I18n.get().t("make_an_appointment")}
                              </Link>
                                                                &nbsp;&nbsp;
                                                                <HelenaProButton label={I18n.get().t("display_on_calendar")} icon="fas fa-calendar" buttonType="default" onClick={() => goToSuggestion(suggestion)}/>
                            </span>
                                                        ) : <HelenaProButton label={I18n.get().t("display_on_calendar")} icon="fas fa-calendar" buttonType="default" onClick={() => goToSuggestion(suggestion)}/>}
                                                    </td>
                                                    :
                                                    <td>
                                                        {ProgendaContext.getContext() === "ADMIN" ? (
                                                            <span>
                              <Link
                                  to={suggestion.url}
                                  className="btn btn-xs btn-default"
                              >
                                {I18n.get().t("make_an_appointment")}
                              </Link>
                                                                &nbsp;&nbsp;
                                                                <a
                                                                    onClick={() => {
                                                                        goToSuggestion(suggestion);
                                                                    }}
                                                                    className="btn btn-xs btn-default"
                                                                >
                                {I18n.get().t("display_on_calendar")}
                              </a>
                            </span>
                                                        ) : (
                                                            <a
                                                                href={suggestion.url}
                                                                className="btn btn-xs btn-default"
                                                            >
                                                                {I18n.get().t("make_an_appointment")}
                                                            </a>
                                                        )}
                                                    </td>}
                                            </tr>
                                        ))
                                )
                            ])}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        );
    }
}
