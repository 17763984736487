export default class SuggestionStateProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableServiceCodes: null,
      selectedServiceCodes: this.props.selectedServiceCodes || [],
      selectableServices: null,
      uniqueSelectableServices: null,
      availableUniqueSelectableServices: null,
      dayFilter: [],
      hourFilter: [],
      postcodeFilter: [],
      minTime: null
    };
  }

  loadSelectableServices() {
    const promises = [];
    const selectableServices = [];
    this.props.selectedCalendars.forEach(calendar => {
      promises.push(
        new Promise((resolve, reject) => {
          //TODO delete sort logic in JS, as it is done in server side, but lost in the loadServices() method, because we save services in a hash
          calendar.loadServices().then(services => {
            Object.values(services)
              .sort((service1, service2) =>
                service1.sortIndex > service2.sortIndex
                  ? 1
                  : service1.createdAt > service2.createdAt
                  ? 1
                  : -1
              )
              .forEach(service => {
                selectableServices.push(service);
              });
            resolve();
          });
        })
      );
    });
    Promise.all(promises).then(() => {
      const selectableServiceCodes = selectableServices
        .map(s => s.label)
        .filter((v, i, filterSelf) => filterSelf.indexOf(v) === i);
      const uniqueSelectableServices = selectableServiceCodes.map(
        code =>
          selectableServices.find(
            service => service.label === code && service.trueTranslation
          ) || selectableServices.find(service => service.label === code)
      );
      const availableUniqueSelectableServices = uniqueSelectableServices.filter(
        service =>
          this.state.availableServiceCodes === null ||
          this.state.availableServiceCodes.includes(service.label)
      );
      if (
        this.props.forceServiceSelect &&
        this.state.selectedServiceCodes.length === 0 &&
        selectableServices.length !== 0
      ) {
        this.setState({
          selectableServices: selectableServices,
          uniqueSelectableServices: uniqueSelectableServices,
          availableUniqueSelectableServices: availableUniqueSelectableServices,
          selectedServiceCodes: [selectableServices[0].label]
        });
      } else if (
        this.props.forceServiceSelectIfOnlyService &&
        this.state.selectedServiceCodes.length === 0 &&
        availableUniqueSelectableServices.length === 1
      ) {
        this.setState({
          selectableServices: selectableServices,
          uniqueSelectableServices: uniqueSelectableServices,
          availableUniqueSelectableServices: availableUniqueSelectableServices,
          selectedServiceCodes: [availableUniqueSelectableServices[0].label]
        });
      } else {
        this.setState({
          selectableServices: selectableServices,
          uniqueSelectableServices: uniqueSelectableServices,
          availableUniqueSelectableServices: availableUniqueSelectableServices
        });
      }
    });
  }

  componentDidMount() {
    if (this.props.selectedCalendars) {
      this.loadSelectableServices();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedCalendars &&
      this.props.selectedCalendars !== prevProps.selectedCalendars
    ) {
      this.loadSelectableServices();
    }
  }

  setSelectedServiceCodes = selectedServiceCodes => {
    this.setState({
      selectedServiceCodes: selectedServiceCodes,
      availableServiceCodes: null
    });
  };

  setDayFilter = dayFilter => {
    this.setState({ dayFilter: dayFilter });
  };

  setHourFilter = hourFilter => {
    this.setState({ hourFilter: hourFilter });
  };

  setPostcodeFilter = postcodeFilter => {
    this.setState({ postcodeFilter: postcodeFilter });
  };

  setMinTime = minTime => {
    this.setState({ minTime: minTime });
  };

  setServiceGroup = serviceCodes => {
    this.setState(
      { availableServiceCodes: serviceCodes },
      this.loadSelectableServices
    );
  };

  render() {
    const childWithProp = React.Children.map(this.props.children, child => {
      if (typeof child.type === "string") {
        /**
         * if the component type is string, it means it's a pure HTML component so we don't have
         * to add the props we have below
         *
         * This fixes warnings like:
         * "React does not recognize the `setCalendarFilter` prop on a DOM element"
         *
         * Definition of type argument from react documentation (https://reactjs.org/docs/react-api.html):
         * The type argument can be either a tag name string (such as 'div' or 'span'),
         *  a React component type (a class or a function), or a React fragment type.
         * */
        return child;
      }

      const {
        calendarsFromParams,
        setCalendarFilter,
        setSpecialityFilter,
        setLanguageFilter,
        calendarFilter,
        specialityFilter,
        languageFilter,
        selectableCalendars,
        selectedCalendars
      } = this.props;

      const {
        selectedServiceCodes,
        uniqueSelectableServices,
        availableUniqueSelectableServices,
        selectableServices,
        dayFilter,
        hourFilter,
        postcodeFilter,
        minTime
      } = this.state;

      return React.cloneElement(child, {
        calendarsFromParams,
        setCalendarFilter,
        setSpecialityFilter,
        setLanguageFilter,
        calendarFilter,
        specialityFilter,
        languageFilter,
        selectableCalendars,
        selectedCalendars,
        setSelectedServiceCodes: this.setSelectedServiceCodes,
        setDayFilter: this.setDayFilter,
        setHourFilter: this.setHourFilter,
        setPostcodeFilter: this.setPostcodeFilter,
        setMinTime: this.setMinTime,
        selectedServiceCodes,
        uniqueSelectableServices,
        availableUniqueSelectableServices,
        selectableServices,
        dayFilter,
        hourFilter,
        postcodeFilter,
        minTime,
        setServiceGroup: this.setServiceGroup
      });
    });

    return childWithProp;
  }
}
