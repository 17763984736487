/* Update : 2024-05-03 09:00 */
let routes = {};

(function() {
    /**
 * File generated by js-routes 2.2.8
 * Based on Rails 7.1.3.4 routes of Progenda::Application
 */
// eslint-disable-next-line
(typeof window !== 'undefined' ? window : this).AppRoutes = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const UnescapedSpecials = "-._~!$&'()*+,;=:@"
        .split("")
        .map((s) => s.charCodeAt(0));
    const UnescapedRanges = [
        ["a", "z"],
        ["A", "Z"],
        ["0", "9"],
    ].map((range) => range.map((s) => s.charCodeAt(0)));
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using (typeof window !== 'undefined' ? window : this).AppRoutes = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (false &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            if (segment.match(/^[a-zA-Z0-9-]$/)) {
                // Performance optimization for 99% of cases
                return segment;
            }
            return (segment.match(/./gu) || [])
                .map((ch) => {
                const code = ch.charCodeAt(0);
                if (UnescapedRanges.find((range) => code >= range[0] && code <= range[1]) ||
                    UnescapedSpecials.includes(code)) {
                    return ch;
                }
                else {
                    return encodeURIComponent(ch);
                }
            })
                .join("");
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return `(${this.build_path_spec(route[1])})`;
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{
  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointment_types/_bulk(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  _bulk_api_v3_center_calendar_appointment_types_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_types"],[2,[7,"/"],[2,[6,"_bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/patients/_bulk(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  _bulk_api_v3_center_patients_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"_bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/patients/_merge(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  _merge_api_v3_center_patients_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"_merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/invitation/accept(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  accept_user_invitation_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:slug_or_id(.:format)
   * @param {any} slug_or_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_path: __jsr.r({"slug_or_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"slug_or_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/accesses/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_access_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/accesses(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_accesses_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"accesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/appointment_messages/:id(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_appointment_message_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"appointment_messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/appointment_messages(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_appointment_messages_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"appointment_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/patient_calls/:id(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_patient_call_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"patient_calls"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/patient_calls(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_patient_calls_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"patient_calls"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/teleconsultations/:id(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_teleconsultation_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"teleconsultations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/teleconsultations(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_teleconsultations_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"teleconsultations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/versions/:id(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_version_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/versions(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointment_versions_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"versions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_appointments_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/booking_attempts/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_booking_attempt_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"booking_attempts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/booking_attempts(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_booking_attempts_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"booking_attempts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/campaigns/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_campaign_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/campaigns(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_campaigns_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/cronofy_syncs/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_cronofy_sync_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"cronofy_syncs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/cronofy_syncs(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_cronofy_syncs_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"cronofy_syncs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/patients/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_patient_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/patients(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_patients_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"patients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/recurrence_previews/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_recurrence_preview_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"recurrence_previews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/recurrence_previews(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_recurrence_previews_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"recurrence_previews"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/services/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/services(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_services_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/stats/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_stat_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/stats(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_stats_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/suggestions/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/suggestions(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_suggestions_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/tasks/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_task_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/tasks(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_tasks_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/waiting_patients/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_waiting_patient_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/waiting_patients(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendar_waiting_patients_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_calendars_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/campaigns/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_campaign_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/campaigns(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_campaigns_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/center_backups(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_center_backups_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"center_backups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/criteria(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_criteria_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"criteria"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/criteria/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_criterium_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"criteria"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/filters/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_filter_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"filters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/filters(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_filters_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"filters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/invitations/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_invitation_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/invitations(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_invitations_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/:patient_id/versions/:id(.:format)
   * @param {any} center_id
   * @param {any} patient_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patient_version_path: __jsr.r({"center_id":{"r":true},"patient_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/:patient_id/versions(.:format)
   * @param {any} center_id
   * @param {any} patient_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patient_versions_path: __jsr.r({"center_id":{"r":true},"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"versions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patients_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/mergers/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patients_merger_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"mergers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/mergers(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patients_mergers_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"mergers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/reminders/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patients_reminder_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"reminders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/reminders(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_patients_reminders_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"reminders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/stats/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_stat_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/stats(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_stats_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/subdomains/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_subdomain_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/subdomains(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_subdomains_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"subdomains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tags/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_tag_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tags(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_tags_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tasks/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_task_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tasks(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_tasks_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/waiting_patients/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_waiting_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/waiting_patients(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_center_waiting_patients_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_centers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/invoices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_invoice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/invoices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_invoices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/new_cronofy_sync(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_new_cronofy_sync_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"new_cronofy_sync"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/purchases/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_purchase_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/purchases(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_purchases_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/pusher_authentications/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_pusher_authentication_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pusher_authentications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/pusher_authentications(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_pusher_authentications_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pusher_authentications"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resources/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_resource_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resource_groups/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_resource_group_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resource_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resource_groups(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_resource_groups_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resource_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_resources_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resources"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_root_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/appointments/:id(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_appointment_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/appointments(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_appointments_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/availability_checks/:id(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_availability_check_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"availability_checks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/availability_checks(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_availability_checks_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"availability_checks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/background_colors/:id(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_background_color_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"background_colors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/background_colors(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_background_colors_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"background_colors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/special_availabilities(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_special_availabilities_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"special_availabilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/special_availabilities/:id(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_special_availability_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"special_availabilities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/timetables/:id(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_timetable_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/timetables(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedule_timetables_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"timetables"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_schedules_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/account(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_account_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/billing(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_billing_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/campaigns(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_campaigns_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/invoices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_invoices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/password(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_password_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/payment(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_payment_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/sms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_sms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/subscriptions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_store_subscriptions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/subprocessors/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_subprocessor_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subprocessors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/subprocessors(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_subprocessors_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subprocessors"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/tokens/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_token_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/tokens(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_tokens_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tokens"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/vat_checks(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_vat_checks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"vat_checks"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/waiting_rooms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_waiting_room_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/waiting_rooms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_waiting_rooms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"waiting_rooms"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/helena_pro/create_user(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_helena_pro_create_user_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"helena_pro"],[2,[7,"/"],[2,[6,"create_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/helena_pro/update_prepaid_sms_count(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_helena_pro_update_prepaid_sms_count_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"helena_pro"],[2,[7,"/"],[2,[6,"update_prepaid_sms_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/helena_pro/update_prepaid_sms_user(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_helena_pro_update_prepaid_sms_user_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"helena_pro"],[2,[7,"/"],[2,[6,"update_prepaid_sms_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointment_resets/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_appointment_reset_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_resets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointment_resets(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_appointment_resets_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_appointments_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments_collections/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_appointments_collection_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments_collections(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_appointments_collections_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_services_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services_collections/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_services_collection_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services_collections(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_services_collections_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/suggestions/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/suggestions(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_suggestions_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_timetable_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_timetables_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables_collections/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_timetables_collection_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables_collections(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendar_timetables_collections_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_calendars_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/appointments/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_appointment_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/appointments(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_appointments_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patient_resets/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_patient_reset_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patient_resets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patient_resets(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_patient_resets_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patient_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_patients_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients_collections/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_patients_collection_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients_collections(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_center_patients_collections_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_centers_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_resource_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables/:id(.:format)
   * @param {any} resource_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_resource_timetable_path: __jsr.r({"resource_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables(.:format)
   * @param {any} resource_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_resource_timetables_path: __jsr.r({"resource_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables_collections/:id(.:format)
   * @param {any} resource_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_resource_timetables_collection_path: __jsr.r({"resource_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables_collections(.:format)
   * @param {any} resource_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_resource_timetables_collections_path: __jsr.r({"resource_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_resources_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/session_tokens(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_session_tokens_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"session_tokens"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/user(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v2_user_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointments/:id(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_calendar_appointment_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointment_types/:id(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_calendar_appointment_type_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointment_types(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_calendar_appointment_types_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointments(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_calendar_appointments_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_calendars_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/patients/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/patients(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_center_patients_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_centers_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/session_tokens(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_session_tokens_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"session_tokens"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/user(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v3_user_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/patients/:id/appointments_list(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  appointments_list_api_v3_center_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"appointments_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments/:appointment_id/cancel(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_appointment_cancel_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments/:appointment_id/cancellation(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_appointment_cancellation_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"cancellation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_appointments_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/availabilities(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_availabilities_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"availabilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/availabilities/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_availability_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"availabilities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/services/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_service_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/services(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_services_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/suggestions/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/suggestions(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendar_suggestions_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calendars_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /callbacks/mandrill(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_mandrill_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"mandrill"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/pipedrive(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_pipedrive_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"pipedrive"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/plivo(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_plivo_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"plivo"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/plivo/reply(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_plivo_reply_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"plivo"],[2,[7,"/"],[2,[6,"reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/ring_ring(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_ring_ring_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"ring_ring"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/ring_ring/reply(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_ring_ring_reply_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"ring_ring"],[2,[7,"/"],[2,[6,"reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/sendgrid(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_sendgrid_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"sendgrid"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/spryng(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_spryng_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"spryng"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/stripe(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_stripe_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"stripe"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/twilio(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_twilio_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"twilio"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /callbacks/twilio/reply(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  callbacks_twilio_reply_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"twilio"],[2,[7,"/"],[2,[6,"reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/cancel(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_user_registration_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/calendars/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/calendars(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_calendars_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"calendars"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/centers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_center_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/centers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_centers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"centers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/dashboard/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_dashboard_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/dashboard(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_dashboard_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/invoices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_invoice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/invoices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_invoices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/ip_alerts_exceptions/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_ip_alerts_exception_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"ip_alerts_exceptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/ip_alerts_exceptions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_ip_alerts_exceptions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"ip_alerts_exceptions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/pages/*id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_page_path: __jsr.r({"id":{"r":true}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[5,[3,"id"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/profiles/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_profile_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/profiles(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_profiles_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/resources/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_resource_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/resources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_resources_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"resources"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_root_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /captain/schedules/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_schedule_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/schedules/:schedule_id/accesses(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_schedule_accesses_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"accesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/schedules(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_schedules_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/stats/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_stat_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/stats(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_stats_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"stats"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subdomains/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_subdomain_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subdomains(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_subdomains_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subdomains"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subscriptions/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_subscription_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subscriptions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_subscriptions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/text_messages/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_text_message_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"text_messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/text_messages(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_text_messages_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"text_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/tickets/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_ticket_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/tickets(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_tickets_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /captain/users/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_user_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/users(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  captain_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  center_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:center_id/availabilities(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  center_availabilities_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"availabilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:center_id/availabilities/:id(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  center_availability_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"availabilities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:center_id/reminder(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  center_reminder_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"reminder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  centers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"centers"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/cronofy_syncs/:id(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cronofy_sync_channel_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"cronofy_syncs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/centers/:center_id/accesses/destroy_all(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  destroy_all_oracle_center_accesses_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[6,"destroy_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/sign_out(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  destroy_user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/accesses/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_access_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/appointment_messages/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_appointment_appointment_message_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"appointment_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/patient_calls/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_appointment_patient_call_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"patient_calls"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/teleconsultations/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_appointment_teleconsultation_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"teleconsultations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/versions/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_appointment_version_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/booking_attempts/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_booking_attempt_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"booking_attempts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/campaigns/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_campaign_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/cronofy_syncs/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_cronofy_sync_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"cronofy_syncs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/patients/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_patient_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/recurrence_previews/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_recurrence_preview_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"recurrence_previews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/services/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/stats/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_stat_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/suggestions/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/tasks/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_task_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/waiting_patients/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_calendar_waiting_patient_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/campaigns/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_campaign_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/criteria/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_criterium_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"criteria"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/filters/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_filter_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"filters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/invitations/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_invitation_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/:patient_id/versions/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} patient_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_patient_version_path: __jsr.r({"center_id":{"r":true},"patient_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/mergers/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_patients_merger_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"mergers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/reminders/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_patients_reminder_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"reminders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/stats/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_stat_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/subdomains/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_subdomain_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tags/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_tag_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tasks/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_task_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/waiting_patients/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_center_waiting_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/invoices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_invoice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/purchases/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_purchase_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/pusher_authentications/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_pusher_authentication_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pusher_authentications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resources/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_resource_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resource_groups/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_resource_group_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resource_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_schedule_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/appointments/:id/edit(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_schedule_appointment_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/availability_checks/:id/edit(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_schedule_availability_check_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"availability_checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/background_colors/:id/edit(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_schedule_background_color_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"background_colors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/special_availabilities/:id/edit(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_schedule_special_availability_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"special_availabilities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/timetables/:id/edit(.:format)
   * @param {any} schedule_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_schedule_timetable_path: __jsr.r({"schedule_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/account/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_account_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/billing/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_billing_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/campaigns/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_campaigns_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/invoices/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_invoices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/password/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_password_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/payment/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_payment_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"payment"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/sms/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_sms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/subscriptions/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_store_subscriptions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/subprocessors/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_subprocessor_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subprocessors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/tokens/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_token_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/waiting_rooms/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_waiting_room_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointment_resets/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_appointment_reset_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_resets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments_collections/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_appointments_collection_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services_collections/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_services_collection_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/suggestions/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_timetable_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables_collections/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_calendar_timetables_collection_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_center_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/appointments/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_center_appointment_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_center_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patient_resets/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_center_patient_reset_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patient_resets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients_collections/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_center_patients_collection_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_resource_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables/:id/edit(.:format)
   * @param {any} resource_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_resource_timetable_path: __jsr.r({"resource_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables_collections/:id/edit(.:format)
   * @param {any} resource_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v2_resource_timetables_collection_path: __jsr.r({"resource_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v3_center_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointments/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v3_center_calendar_appointment_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointment_types/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v3_center_calendar_appointment_type_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/patients/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v3_center_patient_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments/:appointment_id/cancellation/edit(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_calendar_appointment_cancellation_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"cancellation"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/availabilities/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_calendar_availability_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"availabilities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/services/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/suggestions/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/calendars/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/centers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_center_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/dashboard/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_dashboard_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/invoices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_invoice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/ip_alerts_exceptions/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_ip_alerts_exception_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"ip_alerts_exceptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/profiles/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_profile_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/resources/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_resource_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/schedules/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_schedule_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/schedules/:schedule_id/accesses/edit(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_schedule_accesses_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/stats/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_stat_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subdomains/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_subdomain_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subscriptions/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_subscription_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/text_messages/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_text_message_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"text_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/tickets/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_ticket_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/users/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_captain_user_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_center_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:center_id/availabilities/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_center_availability_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"availabilities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:center_id/reminder/edit(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_center_reminder_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"reminder"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/appointments/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_appointment_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/calendars/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/calendars/:calendar_id/services/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/calendars/:calendar_id/versions/:id/edit(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_calendar_version_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/campaigns/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_campaign_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/centers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_center_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/centers/:center_id/accesses/:id/edit(.:format)
   * @param {any} center_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_center_access_path: __jsr.r({"center_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/center_backups/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_center_backup_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"center_backups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/center_resets/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_center_reset_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"center_resets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/countries/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_country_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/cronofy_syncs/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_cronofy_sync_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"cronofy_syncs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/delayed_jobs/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_delayed_job_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"delayed_jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/exports/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_export_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/feature_flags/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_feature_flag_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"feature_flags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/iframes/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_iframe_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"iframes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/invoices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_invoice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/invoices/:invoice_id/refunds/:id/edit(.:format)
   * @param {any} invoice_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_invoice_refund_path: __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"refunds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/languages/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_language_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/patients/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_patient_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/patient_deletions/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_patient_deletion_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"patient_deletions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/pharmacies/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_pharmacy_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"pharmacies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/profiles/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_profile_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/proximities/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_proximity_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"proximities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/purchases/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_purchase_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/resources/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_resource_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/resource_groups/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_resource_group_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"resource_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/sales_channels/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_sales_channel_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"sales_channels"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/schedules/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_schedule_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/schedules/:schedule_id/accesses/edit(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_schedule_accesses_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/specialities/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_speciality_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"specialities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/subscriptions/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_subscription_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_user_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/campaign_email_credit_transactions/:id/edit(.:format)
   * @param {any} user_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_user_campaign_email_credit_transaction_path: __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"campaign_email_credit_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/campaign_sms_credit_transactions/:id/edit(.:format)
   * @param {any} user_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_user_campaign_sms_credit_transaction_path: __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"campaign_sms_credit_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/prepaid_sms_transactions/:id/edit(.:format)
   * @param {any} user_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_user_prepaid_sms_transaction_path: __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"prepaid_sms_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/tokens/:id/edit(.:format)
   * @param {any} user_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_user_token_path: __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/variable_plan_transactions/:id/edit(.:format)
   * @param {any} user_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_user_variable_plan_transaction_path: __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"variable_plan_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/waiting_rooms/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_oracle_waiting_room_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /sales/calendars/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_sales_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/check_ins/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_signage_check_in_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"check_ins"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/reminders/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_signage_reminder_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"reminders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_signage_waiting_room_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars/:id/edit(.:format)
   * @param {any} waiting_room_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_signage_waiting_room_calendar_path: __jsr.r({"waiting_room_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars/:calendar_id/appointments/:id/edit(.:format)
   * @param {any} waiting_room_id
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_signage_waiting_room_calendar_appointment_path: __jsr.r({"waiting_room_id":{"r":true},"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /subdomains/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_subdomain_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/password/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_user_password_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_user_registration_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /embedded/pages/button(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  embedded_pages_button_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"embedded"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"button"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /embedded/pages/suggester(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  embedded_pages_suggester_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"embedded"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"suggester"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/accesses/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_access_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/appointment_messages/new(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_appointment_appointment_message_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"appointment_messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/patient_calls/new(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_appointment_patient_call_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"patient_calls"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/teleconsultations/new(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_appointment_teleconsultation_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"teleconsultations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:appointment_id/versions/new(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_appointment_version_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/booking_attempts/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_booking_attempt_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"booking_attempts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/campaigns/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_campaign_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/cronofy_syncs/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_cronofy_sync_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"cronofy_syncs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/patients/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_patient_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/recurrence_previews/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_recurrence_preview_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"recurrence_previews"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/services/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/stats/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_stat_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/suggestions/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/tasks/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_task_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/waiting_patients/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_calendar_waiting_patient_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/campaigns/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_campaign_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/criteria/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_criterium_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"criteria"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/filters/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_filter_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"filters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/invitations/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_invitation_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_patient_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/:patient_id/versions/new(.:format)
   * @param {any} center_id
   * @param {any} patient_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_patient_version_path: __jsr.r({"center_id":{"r":true},"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/mergers/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_patients_merger_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"mergers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/patients/reminders/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_patients_reminder_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"reminders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/stats/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_stat_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/subdomains/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_subdomain_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tags/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_tag_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/tasks/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_task_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/centers/:center_id/waiting_patients/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_center_waiting_patient_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"waiting_patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/invoices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_invoice_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/purchases/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_purchase_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/pusher_authentications/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_pusher_authentication_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pusher_authentications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_resource_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/resource_groups/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_resource_group_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resource_groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_schedule_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/appointments/new(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_schedule_appointment_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/availability_checks/new(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_schedule_availability_check_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"availability_checks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/background_colors/new(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_schedule_background_color_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"background_colors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/special_availabilities/new(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_schedule_special_availability_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"special_availabilities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/schedules/:schedule_id/timetables/new(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_schedule_timetable_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/account/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_account_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/billing/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_billing_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/campaigns/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_campaigns_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/invoices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_invoices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/password/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_password_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/payment/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_payment_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"payment"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/sms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_sms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/store/subscriptions/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_store_subscriptions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"store"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/subprocessors/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_subprocessor_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subprocessors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/tokens/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_token_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/waiting_rooms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_waiting_room_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointment_resets/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_appointment_reset_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/appointments_collections/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_appointments_collection_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments_collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/services_collections/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_services_collection_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services_collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/suggestions/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_timetable_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/calendars/:calendar_id/timetables_collections/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_calendar_timetables_collection_path: __jsr.r({"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_center_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/appointments/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_center_appointment_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_center_patient_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patient_resets/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_center_patient_reset_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patient_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/centers/:center_id/patients_collections/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_center_patients_collection_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients_collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_resource_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables/new(.:format)
   * @param {any} resource_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_resource_timetable_path: __jsr.r({"resource_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v2/resources/:resource_id/timetables_collections/new(.:format)
   * @param {any} resource_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v2_resource_timetables_collection_path: __jsr.r({"resource_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[6,"timetables_collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v3_center_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointments/new(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v3_center_calendar_appointment_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/calendars/:calendar_id/appointment_types/new(.:format)
   * @param {any} center_id
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v3_center_calendar_appointment_type_path: __jsr.r({"center_id":{"r":true},"calendar_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointment_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v3/centers/:center_id/patients/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v3_center_patient_path: __jsr.r({"center_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v3"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_calendar_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/appointments/:appointment_id/cancellation/new(.:format)
   * @param {any} calendar_id
   * @param {any} appointment_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_calendar_appointment_cancellation_path: __jsr.r({"calendar_id":{"r":true},"appointment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"appointment_id"],[2,[7,"/"],[2,[6,"cancellation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/availabilities/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_calendar_availability_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"availabilities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/services/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /calendars/:calendar_id/suggestions/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_calendar_suggestion_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"suggestions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/calendars/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_calendar_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/centers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_center_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/dashboard/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_dashboard_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/invoices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_invoice_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/ip_alerts_exceptions/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_ip_alerts_exception_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"ip_alerts_exceptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/profiles/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_profile_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/resources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_resource_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/schedules/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_schedule_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/schedules/:schedule_id/accesses/new(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_schedule_accesses_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/stats/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_stat_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"stats"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subdomains/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_subdomain_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/subscriptions/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_subscription_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/text_messages/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_text_message_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"text_messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/tickets/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_ticket_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /captain/users/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_captain_user_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"captain"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_center_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:center_id/availabilities/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_center_availability_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"availabilities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /centers/:center_id/reminder/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_center_reminder_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"reminder"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/appointments/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_appointment_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/calendars/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_calendar_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/calendars/:calendar_id/services/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_calendar_service_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/calendars/:calendar_id/versions/new(.:format)
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_calendar_version_path: __jsr.r({"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/campaigns/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_campaign_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/centers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_center_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/centers/:center_id/accesses/new(.:format)
   * @param {any} center_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_center_access_path: __jsr.r({"center_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"centers"],[2,[7,"/"],[2,[3,"center_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/center_backups/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_center_backup_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"center_backups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/center_resets/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_center_reset_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"center_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/countries/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_country_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/cronofy_syncs/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_cronofy_sync_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"cronofy_syncs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/delayed_jobs/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_delayed_job_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"delayed_jobs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/exports/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_export_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/feature_flags/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_feature_flag_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"feature_flags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/iframes/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_iframe_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"iframes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/invoices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_invoice_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/invoices/:invoice_id/refunds/new(.:format)
   * @param {any} invoice_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_invoice_refund_path: __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"refunds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/languages/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_language_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/patients/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_patient_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/patient_deletions/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_patient_deletion_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"patient_deletions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/pharmacies/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_pharmacy_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"pharmacies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/profiles/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_profile_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/proximities/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_proximity_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"proximities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/purchases/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_purchase_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/resources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_resource_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/resource_groups/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_resource_group_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"resource_groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/sales_channels/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_sales_channel_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"sales_channels"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/schedules/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_schedule_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/schedules/:schedule_id/accesses/new(.:format)
   * @param {any} schedule_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_schedule_accesses_path: __jsr.r({"schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"schedule_id"],[2,[7,"/"],[2,[6,"accesses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/specialities/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_speciality_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"specialities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/subscriptions/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_subscription_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_user_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/campaign_email_credit_transactions/new(.:format)
   * @param {any} user_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_user_campaign_email_credit_transaction_path: __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"campaign_email_credit_transactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/campaign_sms_credit_transactions/new(.:format)
   * @param {any} user_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_user_campaign_sms_credit_transaction_path: __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"campaign_sms_credit_transactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/prepaid_sms_transactions/new(.:format)
   * @param {any} user_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_user_prepaid_sms_transaction_path: __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"prepaid_sms_transactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/tokens/new(.:format)
   * @param {any} user_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_user_token_path: __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/users/:user_id/variable_plan_transactions/new(.:format)
   * @param {any} user_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_user_variable_plan_transaction_path: __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"variable_plan_transactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /oracle/waiting_rooms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_oracle_waiting_room_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oracle"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_rails_conductor_inbound_email_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_rails_conductor_inbound_email_source_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /sales/calendars/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_sales_calendar_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/check_ins/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_signage_check_in_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"check_ins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/reminders/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_signage_reminder_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"reminders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_signage_waiting_room_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars/new(.:format)
   * @param {any} waiting_room_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_signage_waiting_room_calendar_path: __jsr.r({"waiting_room_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars/:calendar_id/appointments/new(.:format)
   * @param {any} waiting_room_id
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_signage_waiting_room_calendar_appointment_path: __jsr.r({"waiting_room_id":{"r":true},"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /subdomains/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_subdomain_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users/invitation/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_user_invitation_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/password/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_user_password_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/sign_up(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_user_registration_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users/sign_in(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /pages/*id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  page_path: __jsr.r({"id":{"r":true}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[5,[3,"id"]]]]]),

  /**
   * Generates rails route to
   * /code/:quick_access(.:format)
   * @param {any} quick_access
   * @param {object | undefined} options
   * @returns {string} route path
   */
  quick_access_path: __jsr.r({"quick_access":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"code"],[2,[7,"/"],[2,[3,"quick_access"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
   * @param {any} signed_blob_id
   * @param {any} variation_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_blob_representation_path: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
   * @param {any} signed_blob_id
   * @param {any} variation_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_blob_representation_proxy_path: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
   * @param {any} inbound_email_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_incinerate_path: __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
   * @param {any} inbound_email_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_reroute_path: __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_sources_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/direct_uploads(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_direct_uploads_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/disk/:encoded_key/*filename(.:format)
   * @param {any} encoded_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_disk_service_path: __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mailgun_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mandrill/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mandrill_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mandrill/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mandrill_inbound_health_check_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/postmark/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_postmark_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/relay/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_relay_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_sendgrid_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
   * @param {any} signed_id
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_service_blob_path: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
   * @param {any} signed_id
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_service_blob_proxy_path: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/invitation/remove(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  remove_user_invitation_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /
   * @param {object | undefined} options
   * @returns {string} route path
   */
  root_path: __jsr.r({}, [7,"/"]),

  /**
   * Generates rails route to
   * /sales/calendars/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sales_calendar_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /sales/calendars(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sales_calendars_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"calendars"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /sales/pages/*id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sales_page_path: __jsr.r({"id":{"r":true}}, [2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[5,[3,"id"]]]]]]]),

  /**
   * Generates rails route to
   * /sales(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sales_root_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /admin/calendars/:calendar_id/appointments/:id/send_reminder(.:format)
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  send_reminder_admin_calendar_appointment_path: __jsr.r({"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_reminder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /sidekiq
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sidekiq_web_path: __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]),

  /**
   * Generates rails route to
   * /signage/check_ins/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_check_in_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"check_ins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/check_ins(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_check_ins_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"check_ins"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /signage/reminders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_reminder_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"reminders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/reminders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_reminders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"reminders"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /signage(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_root_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signage"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_waiting_room_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars/:id(.:format)
   * @param {any} waiting_room_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_waiting_room_calendar_path: __jsr.r({"waiting_room_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars/:calendar_id/appointments/:id(.:format)
   * @param {any} waiting_room_id
   * @param {any} calendar_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_waiting_room_calendar_appointment_path: __jsr.r({"waiting_room_id":{"r":true},"calendar_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars/:calendar_id/appointments(.:format)
   * @param {any} waiting_room_id
   * @param {any} calendar_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_waiting_room_calendar_appointments_path: __jsr.r({"waiting_room_id":{"r":true},"calendar_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[2,[7,"/"],[2,[3,"calendar_id"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms/:waiting_room_id/calendars(.:format)
   * @param {any} waiting_room_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_waiting_room_calendars_path: __jsr.r({"waiting_room_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"waiting_room_id"],[2,[7,"/"],[2,[6,"calendars"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /signage/waiting_rooms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  signage_waiting_rooms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signage"],[2,[7,"/"],[2,[6,"waiting_rooms"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /site(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  site_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /site/contact-fr(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  site_contact_fr_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site"],[2,[7,"/"],[2,[6,"contact-fr"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /site/fonctionnalites(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  site_fonctionnalites_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site"],[2,[7,"/"],[2,[6,"fonctionnalites"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /site/partenaires(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  site_partenaires_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site"],[2,[7,"/"],[2,[6,"partenaires"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /site/tarifs(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  site_tarifs_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site"],[2,[7,"/"],[2,[6,"tarifs"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /sitemap.xml.gz(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sitemap_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sitemap"],[2,[8,"."],[2,[6,"xml"],[2,[8,"."],[2,[6,"gz"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /subdomains/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subdomain_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subdomains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /subdomains(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subdomains_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subdomains"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/disk/:encoded_token(.:format)
   * @param {any} encoded_token
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_rails_disk_service_path: __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /user(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /users/invitation(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_invitation_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users/password(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_password_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_registration_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /users/sign_in(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users/api_user_session(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  users_api_user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"api_user_session"],[1,[2,[8,"."],[3,"format"]]]]]]])}
,
    };
    Utils.define_module("NIL", result);
    return result;
})();

}.call(routes));

export default window.AppRoutes ||  routes.AppRoutes;